// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer-block {
    & {
        padding: remify(30 25);
    }
}

.footer_row {
    &.-padded {
        width: calc(100% + #{remify(50, 16)});
    }

    &.-padded > .col {
        padding-right: remify(50, 16);
    }

    .footer_inner > & > .col:not(:last-child) {
        margin-bottom: 0;
    }

    .footer_inner > & > .col:last-child{
        text-align: right;
    }
}

.footer_text {
    & {
        font-size: remify(15, 16);
    }
}

.footer_logo {
    & {
        width: remify(240, 16);
    }
}

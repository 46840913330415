// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block {
    & {
        padding: 0;
        top: remify(40);
    }
}

.header_logo {
    & {
        max-width: remify(265);
        padding-right: 0;
    }
}

.header_search-form_container {
    & {
        float: right;
        margin-bottom: remify(35, 16);
        margin-right: remify(45, 16);
        width: remify(300, 16);
    }

    & + * {
        clear: both;
    }
}

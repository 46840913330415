// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Hero
\* ------------------------------------------------------------------------ */

.hero_image {
    & {
        height: remify(350);
    }

    &.-tall {
        height: remify(850);
    }
}

.hero_header {
    & {
        padding: remify(35 20);
        text-align: left;
    }
}

.hero_title {
    & {
        font-size: remify(36, 16);
    }
}

.hero_user-content {
    ol,
    p,
    table,
    ul {
        font-size: remify(17, 16);
    }
}

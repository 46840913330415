// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Callout
\* ------------------------------------------------------------------------ */

.callout-block {
    & {
        @include layer("callout");
        padding: remify(15 25);
    }
}

.callout_row {
    &:last-child > .col > .callout_button {
        margin-bottom: 0;
    }
}

// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Article
\* ------------------------------------------------------------------------ */

.article {
    & {
        display: block;
    }
}

.article_image {
    & {
        float: right;
        margin: remify(0 0 50 50, 16);
        width: 30%;
    }

    ~ .article_header,
    ~ .article_content {
        clear: left;
        float: left;
        width: 65%;
        width: calc(70% - #{remify(50, 16)});
    }
}

.article_title {
    & {
        font-size: remify(40, 16);
    }
}

.article_title_small {
    & {
        font-size: remify(18, 40);
    }
}

// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header_logo {
    & {
        max-width: remify(240);
        padding-left: 0;
    }
}

// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

// dark

.content-block.-dark {
    & {
        padding: remify(45 20);
    }

    .col:not(:last-child) > .content_post {
        padding-bottom: 0;
        padding-right: remify(140);
    }

    .content_sidebar {
        margin: remify(-45 -20 -45 0);
        padding: remify(45 20 45 30);
        position: relative;
        width: 100%;
        z-index: 2;
    }

    .content_sidebar:before,
    .content_sidebar:after {
        background: $accent;
        bottom: 0;
        content: "\0020";
        display: block;
        position: absolute;
        top: 0;
        z-index: -1;
    }

    .content_sidebar:before {
        background: url("../media/background-content_sidebar_dark.svg") center center / 100% 100% no-repeat;
        right: 100%;
        width: remify(120);
    }

    .content_sidebar:after {
        left: 0;
        width: 50vw;
    }

    .content_user-content h1,
    .content_user-content h2 {
        font-size: remify(34, 16);
    }

    .content_sidebar .content_user-content ul {
        font-size: remify(20, 16);
    }
}
